import React from 'react'

const FormGroup = ({
  children,
}: {
  children: React.ReactElement | (React.ReactElement | null | '' | undefined)[]
}) => {
  return (
    <>
      {React.Children.count(children) > 1 ? (
        React.Children.map(children, (child) =>
          child ? <div className="py-3.5">{child}</div> : null,
        )
      ) : (
        <div className="py-3.5">{children}</div>
      )}
    </>
  )
}

export default FormGroup
