import { useLocation } from 'react-router-dom'

const useCurrentPath = () => {
  const location = useLocation()
  const currentPath = [location.pathname, location.search, location.hash]
    .filter(Boolean)
    .join('')

  return currentPath
}

export default useCurrentPath
