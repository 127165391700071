import React from 'react'
import cc from 'classcat'
import useIsMounted from 'hooks/useIsMounted'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import dot from 'assets/dot.png'

import styles from './index.module.scss'

const cache: Record<string, boolean> = {}

type Props = {
  image: string
  width?: number | string
  height?: number | string
  className?: string
  children?: any
  style?: any
  innerRef?: any
  loadHandler?: boolean
  alt?: string
  $type?: 'div' | 'image'
}

const BackgroundImage = ({
  image,
  width,
  height,
  className,
  children,
  style,
  innerRef,
  loadHandler,
  alt,
  $type,
}: Props) => {
  const isMounted = useIsMounted()
  // prevents flickering when we re-render the component
  const hasBeenLoadedBefore: boolean = cache[image]
  const [loaded, setLoaded] = React.useState(hasBeenLoadedBefore)

  React.useEffect(() => {
    if (loadHandler && !hasBeenLoadedBefore) {
      const img = new Image()

      img.onload = () => {
        cache[image] = true
        if (isMounted.current) {
          setLoaded(true)
        }
      }

      img.src = image
    }
  }, [loadHandler, hasBeenLoadedBefore, image])

  const props = {
    className: cc([styles.root, 'block bg-gray-400 transition-all', className]),
    style: {
      ...style,
      backgroundImage: !loadHandler || loaded ? `url(${image})` : 'none',
      width,
      height,
    },
    ref: innerRef,
  }

  const element =
    $type === 'div' || ($type == null && children) ? (
      <div {...props}>{children}</div>
    ) : (
      <img alt={alt || 'Background Image'} {...props} src={dot} />
    )

  if (!loadHandler) {
    return element
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={!loadHandler || loaded ? 'loaded' : 'not-loaded'}
        classNames={styles}
        timeout={200}
        appear
      >
        {element}
      </CSSTransition>
    </SwitchTransition>
  )
}

BackgroundImage.defaultProps = {
  style: {},
  loadHandler: true,
}

export default BackgroundImage
